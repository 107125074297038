<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <advanced-table
        @data-loaded="dataLoaded"
        :tableColumns="tableColumns"
        :dataAction="
          isRadarList() ? 'devices/GET_RADAR_DEVICES' : 'devices/GET_DEVICES'
        "
        :dataURLParams="{ type: isRadarList() ? 'radar' : $route.params.type }"
        :createRoute="{
          name: 'device-create',
          params: { type: isRadarList() ? 'radar' : $route.params.type },
        }"
        ref="devicesTable"
        :key="updateCount"
        ><template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '50px' : '400px' }"
          />
        </template>
        <template slot="additional-buttons"
          ><b-button
            :disabled="!listChanged()"
            v-if="isRadarList()"
            class="ml-1"
            variant="primary"
            @click="updateImak"
          >
            {{ $t('enforce') }}
          </b-button>
        </template>
        <template #cell(imakEnabled)="device">
          <b-form-checkbox
            v-model="device.item.imakEnabled"
            name="checkbox-1"
            :value="1"
            :unchecked-value="0"
          >
          </b-form-checkbox>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{
                name: isRadarList()
                  ? 'radar-settings'
                  : $route.params.type + '-settings',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteDevice(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template></advanced-table
      >
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
    AdvancedTable,
  },
  data() {
    return {
      orgData: null,
      currentData: null,
      updateCount: 0,
      tableColumns: [
        this.isRadarList()
          ? {
              key: 'imakEnabled',
              label: i18n.tc('settings.imak'),
              sortable: true,
            }
          : undefined,
        {
          key: 'location',
          label: i18n.tc('settings.device_id'),
          sortable: true,
        },
        {
          key: 'name',
          label: i18n.tc('generalSettings.device_name'),
          sortable: true,
        },
        {
          key: 'serialNumber',
          label: i18n.tc('settings.serial_num'),
          sortable: false,
        },
        { key: 'actions', label: i18n.tc('actions') },
      ],
    };
  },
  methods: {
    async updateImak() {
      const ids = this.$refs.devicesTable.data.map(d => {
        return {
          id: d.id,
          imakEnabled: d.imakEnabled,
        };
      });

      try {
        await this.$store.dispatch('devices/IMAK_UPDATE', {
          devices: ids,
        });

        successToast(
          this.$toast,
          'Pobiranje iz imak naprav uspešno posodobljeno. ',
        );
        this.orgData = null;
        this.$refs.devicesTable.refresh();
      } catch (e) {
        dangerToast(this.$toast, e.message);
      }
    },
    listChanged() {
      return JSON.stringify(this.orgData) != JSON.stringify(this.currentData);
    },
    dataLoaded(data) {
      if (this.orgData == null) {
        this.orgData = JSON.parse(JSON.stringify(data));
        this.$refs.devicesTable.$forceUpdate();
      }
      this.currentData = data;
      this.$forceUpdate();
    },
    isRadarList() {
      return this.$route.meta.type == 'radar';
    },
    deleteDevice(device) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('confirm_device_deletation_description') + device.name + '?',
          {
            title: this.$t('confirm_deletation'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('yes'),
            cancelTitle: this.$t('no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async value => {
          if (value) {
            const res = await this.$store.dispatch(
              'devices/DELETE_DEVICE',
              device.id,
            );
            if (res) {
              this.updateCount++;
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('deleted'),
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: this.$t('generalSettings.device_deleted'),
                },
              });
            }
          }
        });
    },
  },
  watch: {
    '$route.params.type': function(id) {
      setTimeout(() => {
        this.$refs.devicesTable.refresh();
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
