var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('advanced-table',{key:_vm.updateCount,ref:"devicesTable",attrs:{"tableColumns":_vm.tableColumns,"dataAction":_vm.isRadarList() ? 'devices/GET_RADAR_DEVICES' : 'devices/GET_DEVICES',"dataURLParams":{ type: _vm.isRadarList() ? 'radar' : _vm.$route.params.type },"createRoute":{
        name: 'device-create',
        params: { type: _vm.isRadarList() ? 'radar' : _vm.$route.params.type },
      }},on:{"data-loaded":_vm.dataLoaded},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '50px' : '400px' })})})}},{key:"cell(imakEnabled)",fn:function(device){return [_c('b-form-checkbox',{attrs:{"name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(device.item.imakEnabled),callback:function ($$v) {_vm.$set(device.item, "imakEnabled", $$v)},expression:"device.item.imakEnabled"}})]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
              name: _vm.isRadarList()
                ? 'radar-settings'
                : _vm.$route.params.type + '-settings',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.$t('edit')))])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteDevice(data.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)]}}])},[_c('template',{slot:"additional-buttons"},[(_vm.isRadarList())?_c('b-button',{staticClass:"ml-1",attrs:{"disabled":!_vm.listChanged(),"variant":"primary"},on:{"click":_vm.updateImak}},[_vm._v(" "+_vm._s(_vm.$t('enforce'))+" ")]):_vm._e()],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }